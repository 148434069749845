<template>
  <div class="cate_r_slide" v-if="show">
    <div class="left_slide category" id="category_left">
      <router-link to="">&lt;</router-link>
    </div>
    <div class="slide_cate">
      <div class="slide_box">
        <swiper
          class="swiper my_swiper"
          :options="swiperOption"
          v-if="show"
          ref="mySwiper"
        >
          <swiper-slide
            v-for="item in category"
            :key="item.id"
            :data-menu="item.category_code"
            :data-id="item.id"
            class="review"
          >
            {{ item.title }}
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="right_slide category" id="category_right">
      <router-link to="">&gt;</router-link>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
let vm = null;
export default {
  data() {
    return {
      show: true,
      swiperOption: {
        initialSlide: 0,
        slidesPerView: 5,
        loop: true,
        loopAdditionalSlides: 1,
        pagination: false,
        navigation: {
          nextEl: "#category_left",
          prevEl: "#category_right",
        },
        centeredSlides: true, //처음 슬라이드를 가운데 배치/
        slideToClickedSlide: true, //슬라이드 위치로 이동
        on: {
          click: function (e) {
            const id = e.target.dataset.id;
            vm.checkMenu(id, true);
          },
          slideChangeTransitionEnd: function (e) {
            let id = "";
            if (document.querySelectorAll(".review.swiper-slide").length > 0) {
              [...document.querySelectorAll(".review.swiper-slide")].map((sw) =>
                sw.classList.remove("on")
              );
              document
                .querySelector(".swiper-slide.review.swiper-slide-active")
                .classList.add("on");
              const target = document.querySelector(
                ".swiper-slide.review.swiper-slide-active.on"
              );
              id = target.dataset.id;
              vm.checkMenu(id);
            }
          },
        },
      },
      isMove: true,
    };
  },
  props: {
    category: {
      type: Array,
      default: () => [],
      required: true,
    },
    id: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    vm = this;
  },
  computed: {
    swiper() {
      return this.$refs["mySwiper"].swiper;
    },
  },
  methods: {
    checkMenu(id) {
      // console.log(id, this.id, this.isMove, this.open);
      if (parseInt(this.id) !== parseInt(id) && this.isMove && !this.open) {
        // console.log("open", this.open);
        this.$emit("getGoodsCategory", id);
        return false;
      }
    },
    reflash(index = null) {
      const swiper = document.querySelector(".swiper.my_swiper").swiper;
      this.show = false;
      swiper.destroy();
      this.$nextTick(function () {
        if (index !== null) {
          this.swiperOption.initialSlide = index;
        }
        this.show = true;
        swiper.update();
      });
    },
    goSlide(index, move = true, time = 500) {
      this.isMove = move;
      const swiper = document.querySelector(".swiper.my_swiper").swiper;
      swiper.slideToLoop(index, time, true);
    },
  },
  components: { Swiper, SwiperSlide },
};
</script>

<style lang="scss" scoped>
.cate_r_slide {
  .slide_cate {
    .slide_box {
      div {
        line-height: 40px;
        text-align: center;
        &.on {
          color: black;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
